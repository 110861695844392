import {API_URL} from "./config";
import * as config from "./config";

export async function getAlbums() {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }

    try {
        return await fetch(API_URL + 'albums', init)
            .then(response => response.json())
            .then(res => {return res})
    } catch (err) {
        console.log('Fetch Error get albums ------ ', err)
        return null
    }
}

export async function getAlbum(id) {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }

    try {
        return await fetch(API_URL + 'albums/' + id, init)
            .then(response => response.json())
            .then(res => {return res})
    } catch (err) {
        console.log('Fetch Error get album ------ ', err)
        return null
    }
}

export async function createAlbums(album) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');

    var init = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: album
    }

    try {
        return await fetch(API_URL + 'albums', init)
            .then(response => response.json()
                .then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error create albums ------ ', err)
        return null;
    }
}

export async function updateAlbum(album, id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = API_URL + 'albums/' + id

    var init = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: album
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error update albums ------ ', err)
        return null;
    }
}

export async function deleteAlbum(id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = API_URL + 'albums/' + id

    var init = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error delete albums ------ ', err)
        return null;
    }
}

export async function addPicture(title, picture, album) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'pictures';

    const data = new  FormData();

    data.append('title', title);
    data.append('picture',  picture);
    data.append('album', album)

    var init = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: data
    }

    try {
        return await fetch(url, init)
            .then(response => {return response.status})
    } catch (err) {
        console.log('Fetch Error create member ------ ', err)
        return null;
    }
}

export async function editPicture(title, picture, id, album) {
    console.log(title, picture, id)
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'pictures/' + id;

    const data = new FormData();

    data.append('title', title)
    data.append('picture', picture)
    data.append('album', album)

    var init = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: data
    }

    try {
        return await fetch(url, init)
            .then(response => {return response.status})
    } catch (err) {
        console.log('Fetch Error update member ------ ', err)
        return null;
    }
}

export async function deletePicture(id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'pictures/' + id;

    var init = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error delete member ------ ', err)
        return null;
    }
}
