import React, {memo} from 'react';
import {Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import {createDocument} from "../../../api/Document";
import {createAlbums} from "../../../api/Picture";

function AlbumCreator() {
    const [modalShow, setModalShow] = React.useState(false)

    return (
        <>
            <Button variant="secondary" onClick={() => setModalShow(true)}>Créer un album</Button>

            <FormularModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

function FormularModal(props) {
    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    async function handleSubmit() {
        let album = JSON.stringify({
            title: title,
            description: description
        });

        setLoading(true)

        album = await createAlbums(album);

        if (album.status === 201) {
            setLoading(false)
            window.location.reload()
        } else if (album.status === 401) {
            setError('Il y a eut un problème lors de la création')
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Création d'un album
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicLastname">
                        <Form.Label>Titre de l'album</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le titre de l'album" value={title} onChange={e => setTitle(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {title.length} / 150
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicFirstname">
                        <Form.Label>Description de l'album</Form.Label>
                        <Form.Control type="text" placeholder="Entrer la description du document" value={description} onChange={e => setDescription(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {description.length} / 500
                        </Form.Text>
                    </Form.Group>

                    {error !== null ? (
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    ) : null}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {loading ? (
                    <Spinner animation="grow" variant="primary" />
                ) : (
                    <Button variant="primary" onClick={handleSubmit}>Ajouter</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default AlbumCreator;
