import React from 'react'
import {Button, Modal} from 'react-bootstrap';

import MdClose from 'react-ionicons/lib/MdClose';

import {deletePicture} from '../../../api/Picture';

function PictureDelete(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <MdClose fontSize="30px" color="red" onClick={() => setModalShow(true)} />

            <WarningModal
                show={modalShow}
                id={props.id}
                title={props.title}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

function WarningModal(props) {
    async function handleDelete() {
        const deleted  =  await deletePicture(props.id)

        if (deleted.status === 200) {
            window.location.reload()
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmation de suppression
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes-vous sur de vouloir supprimer la photo <b>"{props.title}"</b> ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Annuler</Button>
                <Button variant="danger" onClick={handleDelete}>Confirmer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PictureDelete;
