import React from 'react'
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap'

import MdCreate from "react-ionicons/lib/MdCreate";

import {editPicture} from "../../../api/Picture"

function PictureEditor(props) {
    const [modalShow, setModalShow] = React.useState(false)

    return (
        <>
            <MdCreate fontSize="30px" color="green" onClick={() => setModalShow(true)} />

            <FormularModal
                show={modalShow}
                id={props.id}
                value={props.value}
                album={props.album}
                onHide={() => setModalShow(false)} />
        </>
    )
}

function FormularModal(props) {
    const [title, setTitle] = React.useState(props.value.title);
    const [picture, setPicture] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    async function handleSubmit() {
        setLoading(true)

        const pictureUpdated = await editPicture(title, picture, props.id, props.album)

        if (pictureUpdated === 200) {
            setLoading(false)
            window.location.reload()
        } else if (pictureUpdated === 415) {
            setError('Le fichier n\'est pas une image')
            setLoading(false)
        } else if (pictureUpdated === 401) {
            setError('Il y a eut un problème lors de la modification de la photo')
            setLoading(false)
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Création d'un événement
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicLastname">
                        <Form.Label>Titre de la photo</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le titre du document" value={title} onChange={e => setTitle(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {title.length} / 100
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formPicture">
                        <Form.Label>Photo</Form.Label>
                        <Form.File id="formcheck-api-custom" custom>
                            <Form.File.Input onChange={e => setPicture(e.target.files[0])} />
                            <Form.File.Label data-browse="Ajouter">
                                {picture !== "" ? picture.name : "Photo"}
                            </Form.File.Label>
                        </Form.File>
                    </Form.Group>

                    {error !== null ? (
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    ) : null}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {loading ? (
                    <Spinner animation="grow" variant="primary" />
                ) : (
                    <Button variant="primary" onClick={handleSubmit}>Modifier</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default PictureEditor;
