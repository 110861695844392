import React from "react";
import {Button, Modal} from 'react-bootstrap';

import {deleteAlbum} from '../../../api/Picture'
import MdClose from "react-ionicons/lib/MdClose";
import {deleteDocument} from "../../../api/Document";

function AlbumDelete(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button style={{marginLeft: 20}} variant="danger" onClick={() => setModalShow(true)}>Supprimer</Button>

            <WarningModal
                show={modalShow}
                id={props.id}
                title={props.title}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

function WarningModal(props) {
    async function handleDelete() {
        const deleted = await deleteAlbum(props.id);

        if (deleted.status === 200) {
            window.location.reload()
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmation de suppression
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes-vous sur de vouloir supprimer l'album <b>"{props.title}"</b> ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Annuler</Button>
                <Button variant="danger" onClick={handleDelete}>Confirmer</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AlbumDelete;
