import React, {Component} from 'react';
import {Spinner, Table} from "react-bootstrap";
import {lstEvents} from "../../api/Events";
import Moment from 'react-moment';
import IosInformationCircleOutline from 'react-ionicons/lib/IosInformationCircleOutline'
import EventCreator from "./components/EventCreator";
import {Link} from "react-router-dom";

class Events extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: null,
            loading: true,
        }
    }

    async componentDidMount() {
        this.setState({loading: true})

        const events = await lstEvents()

        await this.setState({events, loading: false})
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <div style={{margin: 20}}>
                    <div style={{marginBottom: 20, textAlign: 'right'}}>
                        <EventCreator />
                    </div>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Titre</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.events !== null ? this.state.events.map(function (event, i) {
                                return (<tr key={i}>
                                    <td style={{fontWeight: 'bold'}}>{event.title}</td>
                                    <td><Moment date={event.departure_date} format="DD.MM.YYYY"/></td>
                                    <td>{event.type}</td>
                                    <td style={{textAlign: 'center', width: 200}}><Link to={'/event/' + event.id}><IosInformationCircleOutline
                                        fontSize="30px" color="#43853d"/></Link></td>
                                </tr>)
                            }) : null}
                            </tbody>
                        </Table>
                    </div>
                </div>
            );
        }
    }
}

export default Events;
