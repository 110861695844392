import React, {Component} from 'react';
import {getAlbums} from "../../api/Picture";
import {Spinner, CardDeck, Card, Button} from "react-bootstrap";
import AlbumCreator from "./components/AlbumCreator";
import AlbumDelete from "./components/AlbumDelete";

class Pictures extends Component {
    constructor(props) {
        super(props);

        this.state = {
            albums: null,
            loading: true,
        }
    }

    async componentDidMount() {
        this.setState({loading: true})

        const albums = await getAlbums()

        await this.setState({albums, loading: false})
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            )
        } else {
            return (
                <div style={{margin: 20}}>
                    <div style={{marginBottom: 20, textAlign: 'right'}}>
                        <AlbumCreator />
                    </div>
                    <div>
                        {this.state.albums !== null ? (
                            <CardDeck>
                                {this.state.albums.map(function (album, i) {
                                    return (
                                        <Card style={{ maxWidth: 300 }} key={i}>
                                            <Card.Body>
                                                <Card.Title>{album.title}</Card.Title>
                                                <Card.Text>
                                                    {album.description}
                                                </Card.Text>
                                                <Button variant="primary" onClick={() => window.location.href = '/pictures/' + album.id}>Voir l'album</Button>
                                                <AlbumDelete id={album.id} title={album.title} />
                                            </Card.Body>
                                        </Card>
                                    )
                                })}
                            </CardDeck>
                        ) : (<h1>Il n'y a encore aucun album</h1>)}
                    </div>
                </div>
            );
        }
    }
}

export default Pictures;
