import React, {Component} from 'react';

import {Button, Col, Form, Row, Alert, Spinner} from "react-bootstrap";
import {login} from "../../api/User";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            error: null,
            loading: false
        }
    }

    _handleChange = event => {
        const {name, value} = event.target

        this.setState({[name]: value})
    }

    _updateLogged = () => {
        this.props.update()
    }

    _handleSubmit = async () => {
        const {username, password} = this.state

        if (username === '' || password === '') {
            this.setState({error: 'Tous les champs sont obligatoire !'})
        } else {
            this.setState({error: null, loading: true})

            const response = await login(this.state)

            if (response.status === 200) {
                this.setState({error: null, loading: false})
                sessionStorage.setItem('token_user', response.data.token)
                this._updateLogged()
            } else {
                await this.setState({error: 'Nom d\'utilisateur ou mot de passe incorrect !', loading: false})
            }
        }
    }

    render() {
        const {username, password, error, loading} = this.state

        return (
            <div className="App">
                <Row className="justify-content-md-center centered">
                    <Col>
                        <Form>
                            <Form.Group controlId="formBasicUsername">
                                <Form.Control
                                    name="username"
                                    type="text"
                                    placeholder="Nom d'utilisateur"
                                    value={ username }
                                    onChange={ this._handleChange }
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder="Mot de passe"
                                    value={ password }
                                    onChange={ this._handleChange }
                                />
                            </Form.Group>

                            {error !== null ? (<Alert variant={'danger'}>{error}</Alert>) : null}

                            {loading ? (
                                <Spinner animation="grow" variant="primary" />
                            ) : (
                                <Button variant="outline-primary" onClick={this._handleSubmit}>
                                    Se connecter
                                </Button>
                            )}
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Login;
