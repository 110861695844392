import React, {Component} from 'react';

import {Nav, Navbar} from "react-bootstrap";

import {Link} from "react-router-dom";

class Navigation extends Component {
    render() {
        let nav = null

        if(sessionStorage.getItem('token_user')) {
            nav =
                <Nav className="justify-content-end">
                    <Nav.Link className={"link"} as={Link} to={"/events"}>Evénements</Nav.Link>
                    <Nav.Link className={"link"} as={Link} to={"/news"}>News</Nav.Link>
                    <Nav.Link className={"link"} as={Link} to={"/comittee"}>Comité</Nav.Link>
                    <Nav.Link className={"link"} as={Link} to={"/documents"}>Documents</Nav.Link>
                    <Nav.Link className={"link"} as={Link} to={"/pictures"}>Photos</Nav.Link>
                    <Nav.Link className={"link"} as={Link} to={"/logout"}>Deconnexion</Nav.Link>
                </Nav>
        }

        return (
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="#">
                    <img
                        alt=""
                        src={window.location.origin + '/logo-blanc_300x364.png'}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                    {' '}
                    Ski Club les Bouquetins
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    {nav}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;
