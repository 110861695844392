import React, {Component} from 'react';
import {lstComittee} from "../../api/Comittee";
import {Spinner, Table} from "react-bootstrap";
import EventCreator from "../Events/components/EventCreator";
import Moment from "react-moment";
import IosInformationCircleOutline from "react-ionicons/lib/IosInformationCircleOutline";
import MemberCreator from "./components/MemberCreator";
import MemberDelete from "./components/MemberDelete";
import MemberEditor from "./components/MemberEditor";

class Comittee extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comittee: null,
            loading: true
        }
    }

    async componentDidMount() {
        this.setState({loading: true})

        const comittee = await lstComittee()

        await this.setState({comittee, loading: false})
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <div style={{margin: 20}}>
                    <div style={{marginBottom: 20, textAlign: 'right'}}>
                        <MemberCreator />
                    </div>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Téléphone</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.comittee !== null ? this.state.comittee.map(function (member, i) {
                                return (<tr key={i}>
                                    <td style={{fontWeight: 'bold'}}>{member.firstname} {member.lastname}</td>
                                    <td>{member.phone}</td>
                                    <td>{member.mail}</td>
                                    <td>{member.role}</td>
                                    <td style={{textAlign: 'center', width: 100}}><MemberEditor id={member.id} value={member} /></td>
                                    <td style={{textAlign: 'center', width: 100}}><MemberDelete id={member.id} lastname={member.lastname} firstname={member.firstname} /></td>
                                </tr>)
                            }) : null}
                            </tbody>
                        </Table>
                    </div>
                </div>
            );
        }
    }
}

export default Comittee;
