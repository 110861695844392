import * as config from './config'

export async function login(user) {
    var init = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            username: user.username,
            password: user.password
        })
    }

    try {
        return await fetch(config.API_URL + 'login_check', init)
            .then(response => response.json()
            .then(data => ({
                    data: data,
                    status: response.status
                })
            ).then(res => {
                return res
            }));
    } catch (err) {
        console.log('Fetch Error Login ------ ', err)
        return null;
    }
}

