import React, {Component} from 'react';
import {Badge, Button, Card, Nav, Spinner, Table} from 'react-bootstrap';

import ParticipantInfo from './ParticipantInfo';

import {getInfoEvent} from "../../../api/Events";
import Moment from "react-moment";
import EventDelete from "./EventDelete";
import EventEditor from "./EventEditor";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class EventInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            text: "",
            type: "",
            link: "",
            date: "",
            participants: null,
            item: 'information',
            loading: false,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});

        const event = await getInfoEvent(this.props.match.params.id);

        await this.setState({title: event.title, text: event.text, type: event.type, link: event.link, date: event.departure_date, participants: event.participants, loading: false})
    }

    NewLineText = (text) => {
        const newText = text.split('\n').map(str => <p>{str}</p>);

        return newText;
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <>
                    <Card style={{margin: 20}}>
                        <Card.Header>
                            <Nav variant="pills" defaultActiveKey={'#' + this.state.item}>
                                <Nav.Item>
                                    <Nav.Link href="#information" onClick={() => this.setState({item: 'information'})}>Information</Nav.Link>
                                </Nav.Item>
                                {this.state.type === 'Sorties' ? (
                                    <Nav.Item>
                                        <Nav.Link href="#participants" onClick={() => this.setState({item: 'participants'})}>Participants  <Badge variant="secondary">{this.state.participants.length}</Badge></Nav.Link>
                                    </Nav.Item>
                                ) : null}
                            </Nav>
                        </Card.Header>

                        {this.state.item === 'information' ? (
                            <Card.Body>
                                <Card.Title>{this.state.title} du <Moment date={this.state.date} format="DD.MM.YYYY"/></Card.Title>
                                <Card.Text>
                                    {this.NewLineText(this.state.text)}
                                </Card.Text>

                                {this.state.type !== 'Sorties' ? (
                                    <Card.Text>
                                        Lien d'inscription : <a href={this.state.link}>{this.state.link}</a>
                                    </Card.Text>
                                ) : null}

                                <EventEditor id={this.props.match.params.id} value={this.state} />
                                <EventDelete id={this.props.match.params.id} title={this.state.title} date={this.state.date}/>
                            </Card.Body>
                        ) : (
                            <Card.Body>
                                <Card.Title>Participants</Card.Title>

                                {this.state.participants.length === 0 ? (
                                    <Card.Text style={{textAlign: 'center'}}>
                                        Il n'y a toujours personne d'inscrit
                                    </Card.Text>
                                ) : (
                                    <Card.Text style={{textAlign: 'right'}}>
                                        <ExcelFile element={<Button variant="primary">Exporter la liste</Button>} filename={"participant-" + this.state.title + "-" + this.state.date}>
                                            <ExcelSheet data={this.state.participants} name="Participants">
                                                <ExcelColumn label="Prénom" value="firstname"/>
                                                <ExcelColumn label="Nom" value="lastname"/>
                                                <ExcelColumn label="Date de naissance" value="birthday"/>
                                                <ExcelColumn label="Membre"
                                                             value={(col) => col.member ? "Membre" : "Non membre"}/>
                                                <ExcelColumn label="Rendez-vous"
                                                             value={(col) => col.appointment !== 1 ? col.appointment !== 2 ? "11h au parking des Perrières" : "9h au parking des Perrières" : "7h30 au Cycle du Vuillonex"}/>
                                                <ExcelColumn label="E-mail" value="mail"/>
                                                <ExcelColumn label="Téléphone" value="phone"/>
                                                <ExcelColumn label="Message" value="message"/>
                                            </ExcelSheet>
                                        </ExcelFile>
                                    </Card.Text>
                                )}


                                {this.state.participants.length === 0 ? null : (
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr style={{textAlign: 'center'}}>
                                            <th>#</th>
                                            <th>Nom</th>
                                            <th>Message</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.participants !== null ? this.state.participants.map(function (participant, i) {
                                            return (<tr key={i}>
                                                <td style={{fontWeight: 'bold'}}>{i + 1}</td>
                                                <td>{participant.lastname} {participant.firstname}</td>
                                                <td style={{textAlign: 'center'}}>{participant.message ? (<img alt="message" src={window.location.origin + '/check-mark.png'} width="30" height="30" />) : (<img alt="noMessage" src={window.location.origin + '/cancel.png'} width="30" height="30" />)}</td>
                                                <td style={{textAlign: 'center', width: 200}}><ParticipantInfo value={participant} /></td>
                                            </tr>)
                                        }) : null}
                                        </tbody>
                                    </Table>
                                )}
                            </Card.Body>
                        )}

                    </Card>
                </>
            );
        }
    }
}

export default EventInformation;
