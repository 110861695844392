import React from 'react';
import {Button, Modal} from 'react-bootstrap';

import MdClose from "react-ionicons/lib/MdClose";

import {deleteMember} from '../../../api/Comittee';

function MemberDelete(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <MdClose fontSize="30px" color="red" onClick={() => setModalShow(true)} />

            <WarningModal
                show={modalShow}
                id={props.id}
                firstname={props.firstname}
                lastname={props.lastname}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

function WarningModal(props) {
    async function handleDelete() {
        const deleted = await deleteMember(props.id);

        if (deleted.status === 200) {
            window.location.href = "/comittee"
        }

    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Confirmation de suppression
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Êtes-vous sur de vouloir supprimer la news <b>"{props.firstname} {props.lastname}"</b> ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Annuler</Button>
                <Button variant="danger" onClick={handleDelete}>Confirmer</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MemberDelete;
