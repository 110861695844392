import * as config from './config'

export async function lstDocuments() {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    }

    try {
        return await fetch(config.API_URL + 'documents', init)
            .then(response => response.json())
            .then(res => { return res });
    } catch (err) {
        console.log('Fetch Error get documents ------ ', err)
        return null;
    }
}

export async function createDocument(documents, document) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');

    const data = new FormData();

    data.append("filename", documents.filename)
    data.append("description", documents.description)
    data.append('document', document)

    var init = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: data
    }

    try {
        return await fetch(config.API_URL + 'documents', init)
            .then(response => {return response.status})
    } catch (err) {
        console.log('Fetch Error create document ------ ', err)
        return null;
    }
}

export async function editDocument(document, doc, id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'documents/' + id;

    const data = new FormData();

    data.append('filename', document.filename);
    data.append('description', document.description);
    data.append('document', doc);

    var init = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: data
    }

    try {
        return await fetch(url, init)
            .then(response => {return response.status})
    } catch (err) {
        console.log('Fetch Error update member ------ ', err)
        return null;
    }
}

export async function deleteDocument(document) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'documents/' + document;

    var init = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error delete member ------ ', err)
        return null;
    }
}
