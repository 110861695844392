import React from 'react'
import {Button, Image, Modal} from 'react-bootstrap'
import IosImage from "react-ionicons/lib/IosImage";

function PictureShow(props) {
    const [modalShow, setModalShow] = React.useState(false)

    return (
        <>
            <IosImage fontSize="30px" color="black" onClick={() => setModalShow(true)} />
            <PictureModal
                show={modalShow}
                picture={props.picture}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

function PictureModal(props) {
    const [picture, setPicture] = React.useState(props.picture)

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {picture.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{textAlign: 'center'}}>
                <Image style={{width: '30%', height: '30%'}} src={picture.link} rounded />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PictureShow;
