import * as config from './config'

export async function lstComittee() {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }

    try {
        return await fetch(config.API_URL + 'comittee', init)
            .then(response => response.json())
            .then(res => {
                return res
            });
    } catch (err) {
        console.log('Fetch Error get comittee ------ ', err)
        return null;
    }
}

export async function getMember(id) {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }

    try {
        return await fetch(config.API_URL + 'comittee/' + id, init)
            .then(response => response.json())
            .then(res => {return res});
    } catch (err) {
        console.log('Fetch Error get member info ------ ', err);
        return null;
    }
}

export async function createMember(member, picture) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');

    const data = new FormData();

    data.append('firstname', member.firstname);
    data.append('lastname', member.lastname);
    data.append('phone', member.phone);
    data.append('mail', member.mail);
    data.append('picture', picture);
    data.append('role', member.role);

    var init = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: data
    }

    try {
        return await fetch(config.API_URL + 'comittee', init)
            .then(response => {return response.status})
    } catch (err) {
        console.log('Fetch Error create member ------ ', err)
        return null;
    }
}

export async function editMember(member, picture, id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'comittee/' + id;

    const data = new FormData();

    data.append('firstname', member.firstname);
    data.append('lastname', member.lastname);
    data.append('phone', member.phone);
    data.append('mail', member.mail);
    data.append('picture', picture);
    data.append('role', member.role);

    var init = {
        method: 'POST',
        headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: data
    }

    try {
        return await fetch(url, init)
            .then(response => {return response.status})
    } catch (err) {
        console.log('Fetch Error update member ------ ', err)
        return null;
    }
}

export async function deleteMember(member) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'comittee/' + member;

    var init = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error delete member ------ ', err)
        return null;
    }
}
