import React, {Component} from 'react';
import {Spinner, Table} from "react-bootstrap";

import DocumentCreator from "./components/DocumentCreator";
import DocumentEditor from "./components/DocumentEditor";
import DocumentDelete from "./components/DocumentDelete";

import {lstDocuments} from "../../api/Document";

import MdDocument from "react-ionicons/lib/MdDocument";

class Documents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: null,
            loading: true
        }
    }

    async componentDidMount() {
        this.setState({loading: true})

        const documents = await lstDocuments()

        await this.setState({documents, loading: false})
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <div style={{margin: 20}}>
                    <div style={{marginBottom: 20, textAlign: 'right'}}>
                        <DocumentCreator />
                    </div>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Description</th>
                                <th style={{textAlign: 'center'}}>Lien</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.documents !== null ? this.state.documents.map(function (document, i) {
                                return (<tr key={i}>
                                    <td style={{fontWeight: 'bold'}}>{document.filename}</td>
                                    <td>{document.description}</td>
                                    <td style={{textAlign: 'center', widht: 100}}><a href={document.link}><MdDocument fontSize="30px" color="black" /></a></td>
                                    <td style={{textAlign: 'center', width: 100}}><DocumentEditor id={document.id} value={document} /></td>
                                    <td style={{textAlign: 'center', width: 100}}><DocumentDelete id={document.id}  filename={document.filename} /></td>
                                </tr>)
                            }) : null}
                            </tbody>
                        </Table>
                    </div>
                </div>
            );
        }
    }
}

export default Documents;
