import React, {Component} from 'react';
import {Spinner, Table} from "react-bootstrap";
import Moment from "react-moment";
import IosInformationCircleOutline from "react-ionicons/lib/IosInformationCircleOutline";
import NewsCreator from "./components/NewsCreator";
import {lstNews} from "../../api/News";

class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            news: null,
            loading: true
        }
    }

    async componentDidMount() {
        this.setState({loading: true})

        const news = await lstNews()

        await this.setState({news, loading: false})
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <div style={{margin: 20}}>
                    <div style={{marginBottom: 20, textAlign: 'right'}}>
                        <NewsCreator />
                    </div>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Titre</th>
                                <th>Date de parution</th>
                                <th>Type</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.news !== null ? this.state.news.map(function (n, i) {
                                return (<tr key={i}>
                                    <td style={{fontWeight: 'bold'}}>{n.title}</td>
                                    <td><Moment date={n.date} format="DD.MM.YYYY"/></td>
                                    <td>{n.type}</td>
                                    <td style={{textAlign: 'center', width: 200}}><a href={'/news/' + n.id}><IosInformationCircleOutline
                                        fontSize="30px" color="#43853d"/></a></td>
                                </tr>)
                            }) : null}
                            </tbody>
                        </Table>
                    </div>
                </div>
            );
        }
    }
}

export default News;
