import React, {Component} from "react";
import './App.css';

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import Login from "./components/Login";

import Navigation from "./components/Navigation";

import Events from "./components/Events";
import EventInformation from "./components/Events/components/EventInformation";

import News from "./components/News";
import NewsInformation from "./components/News/components/NewsInformation";

import Comittee from "./components/Comittee";
import Documents from "./components/Documents";
import Pictures from "./components/Pictures";
import AlbumInformation from "./components/Pictures/components/AlbumInformation";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: sessionStorage.getItem('token_user'),
    }
  }

  componentDidMount() {
    this.setState({token: sessionStorage.getItem('token_user')})
  }

  updateLogged = () => {
    this.setState({token: sessionStorage.getItem('token_user')})
  }

  _logout = () => {
    sessionStorage.removeItem('token_user')
    this.updateLogged()
  }

  render() {
    return (
        <Router>
          <Navigation />
          <Switch>
            <Route path='/events' render={() => (
                this.state.token ? (<Events />) : (<Redirect to='/' />)
            )} />

            <Route exact path='/event/:id' component={this.state.token ? EventInformation : Login} />

            <Route exact path='/news/:id' component={this.state.token ? NewsInformation : Login} />

            <Route path='/news' render={() => (
                this.state.token ? (<News />) : (<Redirect to='/' />)
            )} />

            <Route path='/comittee' render={() => (
                this.state.token ? (<Comittee />) : (<Redirect to='/' />)
            )} />

            <Route path='/documents' render={() => (
                this.state.token ? (<Documents />) : (<Redirect to='/' />)
            )} />

            <Route exact path='/pictures/:id' component={this.state.token ? AlbumInformation : Login} />

            <Route path='/pictures' render={() => (
                this.state.token ? (<Pictures />) : (<Redirect to='/' />)
            )} />

            <Route exact path='/' render={() => (
                !this.state.token ? (
                    <Login update={this.updateLogged} />
                ) : (
                    <Redirect to='/events' />
                )
            )} />

            <Route path='/logout' render={() => (
                this.state.token ? (
                    this._logout()
                ) : (
                    <Redirect to='/' />
                )
            )} />
          </Switch>
        </Router>
    );
  }
}

export default App;
