import * as config from './config'

export async function lstEvents() {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    }

    try {
        return await fetch(config.API_URL + 'events', init)
            .then(response => response.json())
            .then(res => { return res });
    } catch (err) {
        console.log('Fetch Error get events ------ ', err)
        return null;
    }
}

export async function getInfoEvent(id) {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }

    var url = config.API_URL + 'events/' + id;

    try {
        return await fetch(url, init)
            .then(response => response.json())
            .then(res => { return res });
    } catch (err) {
        console.log('Fetch Error get event information ------ ', err)
        return null;
    }
}

export async function createEvent(event) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');

    var init = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: event
    }

    try {
        return await fetch(config.API_URL + 'events', init)
            .then(response => response.json()
                .then(data => ({
                    data: data,
                    status: response.status
                })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error create event ------ ', err)
        return null;
    }
}

export async function editEvent(event, id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'events/' + id;

    var init = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: event
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error update event ------ ', err)
        return null;
    }
}

export async function deleteEvent(event) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'events/' + event;

    var init = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error delete event ------ ', err)
        return null;
    }
}
