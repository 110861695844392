import * as config from './config'

export async function lstNews() {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try {
        return await fetch(config.API_URL + 'news', init)
            .then(response => response.json())
            .then(res => { return res });
    } catch (err) {
        console.log('Fetch Error get news ------ ', err)
        return null;
    }
}

export async function getInfoNews(id) {
    var init = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
    }

    var url = config.API_URL + 'news/' + id;

    try {
        return await fetch(url, init)
            .then(response => response.json())
            .then(res => { return res });
    } catch (err) {
        console.log('Fetch Error get news information ------ ', err)
        return null;
    }
}

export async function createNews(news) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');

    var init = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: news
    }

    try {
        return await fetch(config.API_URL + 'news', init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error create news ------ ', err)
        return null;
    }
}

export async function editNews(news, id) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'news/' + id;

    var init = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
        body: news
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error update news ------ ', err)
        return null;
    }
}

export async function deleteNews(news) {
    const bearer = 'Bearer ' + sessionStorage.getItem('token_user');
    const url = config.API_URL + 'news/' + news;

    var init = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearer,
            'Access-Control-Allow-Credentials': true,
        },
    }

    try {
        return await fetch(url, init)
            .then(response => response.json()
                .then(data => ({
                        data: data,
                        status: response.status
                    })
                ).then(res => {
                    return res
                }))
    } catch (err) {
        console.log('Fetch Error delete news ------ ', err)
        return null;
    }
}
