import React from 'react';
import {Alert, Button, Form, Modal, Spinner} from "react-bootstrap";
import {editNews} from "../../../api/News";
import {useHistory} from "react-router-dom";

function NewsEditor(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>Modifier</Button>

            <FormularModal
                show={modalShow}
                id={props.id}
                value={props.value}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

function FormularModal(props) {
    const [title, setTitle] = React.useState(props.value.title);
    const [text, setText] = React.useState(props.value.text);
    const [type, setType] = React.useState(props.value.type);
    const [date, setDate] = React.useState(props.value.date);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    async function handleSubmit() {
        const news = JSON.stringify({
            title: title,
            text: text,
            type: type,
            date: date,
            user: {
                id: 5
            }
        });

        setLoading(true)

        const newsEdited = await editNews(news, props.id);

        if (newsEdited.status === 200) {
            setLoading(false)
            window.location.reload()
        } else if (newsEdited.status === 401) {
            setError('Il y a eut un problème lors de la modification')
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modification d'une news
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Titre <b style={{color: 'red'}}>*</b></Form.Label>
                        <Form.Control type="text" placeholder="Entrer le titre" value={title} onChange={e => setTitle(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {title.length} / 100
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Informations <b style={{color: 'red'}}>*</b></Form.Label>
                        <Form.Control as="textarea" rows={3} value={text} onChange={e => setText(e.target.value)}/>
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {text.length} / 2000
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="typeSelector">
                        <Form.Label>Type <b style={{color: 'red'}}>*</b></Form.Label>
                        <Form.Control as="select" onChange={e => setType(e.target.value)} value={type}>
                            <option disabled>------</option>
                            <option value="Sorties">Sorties</option>
                            <option value="Week-end">Week-end</option>
                            <option value="Autres">Autres</option>
                        </Form.Control>
                    </Form.Group>

                    {error !== null ? (
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    ) : null}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {loading ? (
                    <Spinner animation="grow" variant="primary" />
                ) : (
                    <Button variant="primary" onClick={handleSubmit}>Modifier</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default NewsEditor;
