import React from 'react';
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap';

import MdCreate from "react-ionicons/lib/MdCreate";

import {editDocument} from "../../../api/Document";

function DocumentEditor(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <MdCreate fontSize="30px" color="green" onClick={() => setModalShow(true)} />

            <FormularModal
                show={modalShow}
                id={props.id}
                value={props.value}
                onHide={() => setModalShow(false)} />
        </>
    )
}

function FormularModal(props) {
    const [filename, setFilename] = React.useState(props.value.filename);
    const [description, setDescription] = React.useState(props.value.description);
    const [document, setDocument] = React.useState("")
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    async function handleSubmit() {
        const formData = new FormData();

        formData.append('filename', filename)
        formData.append('description', description)
        formData.append('document', document)

        const documents = {
            filename: filename,
            description: description
        }

        setLoading(true)

        const documentUpdated = await editDocument(documents, document, props.id)

        if (documentUpdated === 200) {
            setLoading(false)
            window.location.reload()
        } else if (documentUpdated === 415) {
            setError('Le fichier n\'est pas un PDF')
            setLoading(false)
        } else if (documentUpdated === 401) {
            setError('Il y a eut un problème lors de l\'ajout du document')
            setLoading(false)
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Création d'un événement
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicLastname">
                        <Form.Label>Titre du document</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le titre du document" value={filename} onChange={e => setFilename(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {filename.length} / 100
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicFirstname">
                        <Form.Label>Description du document</Form.Label>
                        <Form.Control type="text" placeholder="Entrer la description du document" value={description} onChange={e => setDescription(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {description.length} / 200
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formPicture">
                        <Form.Label>Document</Form.Label>
                        <Form.File id="formcheck-api-custom" custom>
                            <Form.File.Input onChange={e => setDocument(e.target.files[0])} />
                            <Form.File.Label data-browse="Ajouter">
                                {document !== "" ? document.name : "Document"}
                            </Form.File.Label>
                        </Form.File>
                    </Form.Group>

                    {error !== null ? (
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    ) : null}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {loading ? (
                    <Spinner animation="grow" variant="primary" />
                ) : (
                    <Button variant="primary" onClick={handleSubmit}>Modifier</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default DocumentEditor;
