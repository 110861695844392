import React, {Component} from 'react';

import {Accordion, Alert, Badge, Button, Card, Form, Spinner, Table} from 'react-bootstrap';
import IosImage from "react-ionicons/lib/IosImage";

import {addPicture, getAlbum, updateAlbum} from "../../../api/Picture";

import PictureEditor from "./PictureEditor";
import PictureDelete from "./PictureDelete";
import PictureCreator from "./PictureCreator";
import PictureShow from "./PictureShow";

class AlbumInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            description: "",
            pictures: null,
            newpictures: null,
            loading: true,
            error: null
        }
    }

    async componentDidMount() {
        this.setState({loading: true});

        const album = await getAlbum(this.props.match.params.id)

        await this.setState({title: album.title, description: album.description, pictures: album.pictures, loading: false})
    }

    handleSubmit = async () => {
        const album = JSON.stringify({title: this.state.title, description: this.state.description})

        this.setState({loading: true})

        const albumEdited = await updateAlbum(album, this.props.match.params.id)

        if (albumEdited.status === 200) {
            window.location.reload()
        } else if (albumEdited.status === 401) {
            this.setState({error: 'Il y a eut un problème lors de la modification'})
        }
    }

    handleUpload = async (event) => {
        let pictures = []

        Array.from(event.target.files).forEach(file  => {
            const picture = {title: file.name, picture: file}

            pictures.push(picture)
        })

        await this.setState({newpictures: pictures})
    }

    handleSubmitFiles = async () => {
        let error = false
        for (let i = 0; i < this.state.newpictures.length; i++) {
            let pictureAdded = await addPicture(this.state.newpictures[i].title, this.state.newpictures[i].picture, this.props.match.params.id)

            if (pictureAdded !== 201) {
                error = true
            }
        }

        if (error !== true) {
            window.location.reload()
        }
    }

    render() {
        const {title, description, pictures, error} = this.state

        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%', width: '100%', alignItems: 'center',  justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        }
        return (
            <div style={{margin: 20}}>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                            Information générale
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Form>
                                    <Form.Group controlId="formBasicLastname">
                                        <Form.Label>Titre de l'album</Form.Label>
                                        <Form.Control type="text" placeholder="Entrer le titre de l'album" value={title} onChange={e => this.setState({title: e.target.value})} />
                                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                                            {title.length} / 150
                                        </Form.Text>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicFirstname">
                                        <Form.Label>Description de l'album</Form.Label>
                                        <Form.Control type="text" placeholder="Entrer la description du document" value={description} onChange={e => this.setState({description: e.target.value})} />
                                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                                            {description.length} / 500
                                        </Form.Text>
                                    </Form.Group>

                                    {error !== null ? (
                                        <Alert variant='danger'>
                                            {error}
                                        </Alert>
                                    ) : null}

                                    <Button variant="primary" onClick={this.handleSubmit}>Modifier</Button>
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="2">
                            Photos <Badge variant="success">{pictures.length}</Badge>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <div style={{marginBottom: 20, textAlign: 'right'}}>
                                    <PictureCreator album={this.props.match.params.id} />
                                </div>
                                {this.state.pictures != null && this.state.pictures.length > 0 ? (
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Titre</th>
                                            <th style={{textAlign: 'center'}}>Photo</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.pictures.map((picture, i) => (
                                            <tr key={i}>
                                                <td>{i}</td>
                                                <td>{picture.title}</td>
                                                <td style={{textAlign: 'center', width: 100}}><PictureShow picture={picture} /></td>
                                                <td style={{textAlign: 'center', width: 100}}><PictureEditor id={picture.id} value={picture} album={this.props.match.params.id} /></td>
                                                <td style={{textAlign: 'center', width: 100}}><PictureDelete id={picture.id}  title={picture.title} /></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                ) : null}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}

export default AlbumInformation;
