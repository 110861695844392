import React from 'react'
import {Alert, Button, Form, Modal, Spinner, Table} from 'react-bootstrap'

import {addPicture} from "../../../api/Picture";

function PictureCreator(props) {
    const [modalShow, setModalShow] = React.useState(false)

    return (
        <>
            <Button variant="secondary" onClick={() => setModalShow(true)}>Créer un nouveau document</Button>

            <FormularModal
                show={modalShow}
                album={props.album}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

function FormularModal(props) {
    const [pictures, setPictures] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)

    /*async function handleSubmit() {
        const formData = new FormData();

        formData.append('filename', filename)
        formData.append('description', description)
        formData.append('document', document)

        const documents = {
            filename: filename,
            description: description
        }

        setLoading(true)

        const documentCreated = await createDocument(documents, document)

        if (documentCreated === 201) {
            setLoading(false)
            window.location.reload()
        } else if (documentCreated === 422) {
            setError('Il n\'y a pas de fichier')
            setLoading(false)
        } else if (documentCreated === 415) {
            setError('Le fichier n\'est pas un PDF')
            setLoading(false)
        } else if (documentCreated === 401) {
            setError('Il y a eut un problème lors de l\'ajout du document')
            setLoading(false)
        }
    }*/

    async function handleUpload(event) {
        let pictures = []

        Array.from(event.target.files).forEach(file  => {
            const picture = {title: file.name, picture: file}

            pictures.push(picture)
        })

        setPictures(pictures)
    }

    async function handleSubmitFiles() {
        setLoading(true)
        let error = false
        for (let i = 0; i < pictures.length; i++) {
            let pictureAdded = await addPicture(pictures[i].title, pictures[i].picture, props.album)

            if (pictureAdded !== 201) {
                error = true
            }
        }

        if (error !== true) {
            window.location.reload()
        } else {
            setError('Il y a eut un problème')
            setLoading(false)
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ajout de photo
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input type="file" multiple onChange={handleUpload} />

                <br />
                <br />

                {pictures != null && pictures.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Titre</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pictures.map((picture, i) => (
                            <tr key={i}>
                                <td>{i}</td>
                                <td><Form.Control type="text" placeholder="Entrer le titre de l'image" value={picture.title} onChange={e => {
                                    let items = [...pictures]
                                    let item = {...items[i]}
                                    item.title = e.target.value
                                    items[i] = item
                                    setPictures(items)
                                }} />
                                    {picture.name}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ) : null}

                {error !== null ? (
                    <Alert variant='danger'>
                        {error}
                    </Alert>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {loading ? (
                    <Spinner animation="grow" variant="primary" />
                ) : (
                    <Button variant="primary" onClick={handleSubmitFiles}>Enregistrer</Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

export default PictureCreator;
