import React, {Component} from 'react';
import {Card, Spinner} from 'react-bootstrap';

import {getInfoNews} from "../../../api/News";
import Moment from "react-moment";
import NewsEditor from "./NewsEditor";
import NewsDelete from "./NewsDelete";

class NewsInformation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            text: "",
            type: "",
            date: "",
            loading: false,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});

        const news = await getInfoNews(this.props.match.params.id);

        await this.setState({title: news.title, text: news.text, type: news.type, date: news.date, loading: false})
    }

    NewLineText = (text) => {
        const newText = text.split('\n').map((str, i) => <p key={i}>{str}</p>);

        return newText;
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{margin: 20, display: 'flex', height: '100%',
                    width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading..</span>
                    </Spinner>
                </div>
            );
        } else {
            return (
                <>
                    <Card style={{margin: 20}}>
                        <Card.Header as="h5">{this.state.title}</Card.Header>
                        <Card.Body>

                            <blockquote className="blockquote mb-0">
                                {this.NewLineText(this.state.text)}
                                <footer className="blockquote-footer" style={{marginBottom: 20}}>
                                    <Moment date={this.state.date} format="DD.MM.YYYY"/>
                                </footer>
                            </blockquote>

                            <NewsEditor id={this.props.match.params.id} value={this.state} />
                            <NewsDelete id={this.props.match.params.id} title={this.state.title} />
                        </Card.Body>
                    </Card>
                </>
            );
        }
    }
}

export default NewsInformation;
