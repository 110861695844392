import React from 'react';
import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import IosInformationCircleOutline from "react-ionicons/lib/IosInformationCircleOutline";
import Moment from "react-moment";

function ParticipantInfo(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <a onClick={() => setModalShow(true)}><IosInformationCircleOutline
                fontSize="30px" color="#43853d"/></a>

            <InfoModal
                show={modalShow}
                info={props.value}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

function InfoModal(props) {
    function renderSwitch() {
        switch (props.info.appointment) {
            case 1:
                return '7h30 au Cycle du Vuillonex';
                break;
            case 2:
                return '9h au parking des Perrières';
                break;
            case 3:
                return '11h au parking des Perrières';
                break;
            default:
                return '7h30 au Cycle du Vuillonex';
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.info.lastname} {props.info.firstname}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row style={{marginBottom: 20}}>
                        <Col xs={6} md={4} style={{fontWeight: 'bold'}}>
                            Date de naissance :
                        </Col>
                        <Col xs={12} md={8}>
                            <Moment date={props.info.birthday} format="DD.MM.YYYY"/>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: 20}}>
                        <Col xs={6} md={4} style={{fontWeight: 'bold'}}>
                            Mail :
                        </Col>
                        <Col xs={12} md={8}>
                            {props.info.mail}
                        </Col>
                    </Row>

                    <Row style={{marginBottom: 20}}>
                        <Col xs={6} md={4} style={{fontWeight: 'bold'}}>
                            Membre :
                        </Col>
                        <Col xs={12} md={8}>
                            {props.info.member ? (<img src={window.location.origin + '/check-mark.png'} width="30" height="30" />) : (<img src={window.location.origin + '/cancel.png'} />) }
                        </Col>
                    </Row>

                    <Row style={{marginBottom: 20}}>
                        <Col xs={6} md={4} style={{fontWeight: 'bold'}}>
                            Lieu de rendez-vous :
                        </Col>
                        <Col xs={12} md={8}>
                            {renderSwitch()}
                        </Col>
                    </Row>

                    {props.info.message ? (
                        <Row style={{marginBottom: 20}}>
                            <Col xs={6} md={4} style={{fontWeight: 'bold'}}>
                                Message :
                            </Col>
                            <Col xs={12} md={8}>
                                {props.info.message}
                            </Col>
                        </Row>
                    ) : null}

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ParticipantInfo;
