import React from 'react';
import {Alert, Button, Form, Modal, Spinner} from 'react-bootstrap';

import MdCreate from "react-ionicons/lib/MdCreate";

import {editMember} from '../../../api/Comittee';

function MemberEditor(props) {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <MdCreate fontSize="30px" color="green" onClick={() => setModalShow(true)} />

            <FormularModal
                show={modalShow}
                id={props.id}
                value={props.value}
                onHide={() => setModalShow(false)} />
        </>
    )
}

function FormularModal(props) {
    console.log(props.value.picture)
    const [firstname, setFirstname] = React.useState(props.value.firstname);
    const [lastname, setLastname] = React.useState(props.value.lastname);
    const [phone, setPhone] = React.useState(props.value.phone);
    const [mail, setMail] = React.useState(props.value.mail);
    const [picture, setPicture] = React.useState(props.value.picture);
    const [role, setRole] = React.useState(props.value.role);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);

    async function handleSubmit() {
        const member = {
            id: props.id,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            mail: mail,
            picture: picture,
            role: role
        };

        setLoading(true)

        const memberEdited = await editMember(member, picture, props.id);

        if (memberEdited === 200) {
            setLoading(false)
            window.location.reload()
        } else if (memberEdited === 401) {
            setError('Il y a eut un problème lors de la modification')
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modification d'un membre
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicLastname">
                        <Form.Label>Nom de famille</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le nom de famille" value={lastname} onChange={e => setLastname(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {lastname.length} / 100
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicFirstname">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le prénom" value={firstname} onChange={e => setFirstname(e.target.value)} />
                        <Form.Text className="text-muted" style={{textAlign: 'right', marginRight: 5}}>
                            {firstname.length} / 100
                        </Form.Text>
                    </Form.Group>

                    <Form.Group controlId="formBasicPhone">
                        <Form.Label>N° de téléphone (optionel)</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le n° de téléphone" value={phone} onChange={e => setPhone(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Adresse email (optionel)</Form.Label>
                        <Form.Control type="text" placeholder="Entrer l'adresse email" value={mail} onChange={e => setMail(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId="formPicture">
                        <Form.Label>Photo</Form.Label>
                        <Form.File id="formcheck-api-custom" custom>
                            <Form.File.Input onChange={e => setPicture(e.target.files[0])} />
                            <Form.File.Label data-browse="Ajouter">
                                {picture !== "" ? picture.name : "Photo du membre"}
                            </Form.File.Label>
                        </Form.File>
                    </Form.Group>

                    <Form.Group controlId="formBasicRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Control type="text" placeholder="Entrer le rôle" value={role} onChange={e => setRole(e.target.value)} />
                    </Form.Group>

                    {error !== null ? (
                        <Alert variant='danger'>
                            {error}
                        </Alert>
                    ) : null}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>Close</Button>
                {loading ? (
                    <Spinner animation="grow" variant="primary" />
                ) : (
                    <Button variant="primary" onClick={handleSubmit}>Modifier</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default MemberEditor;
